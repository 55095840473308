<template>
  <v-app>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="products"
        :page.sync="page"
        :server-items-length="meta.total"
        :items-per-page="meta.per_page"
        :loading="loading"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <state-indicate ref="indicate" v-slot:default="{isLoading}">
            <v-toolbar flat>
              <v-toolbar-title>Products List</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark class="mb-2" color="primary" v-on:click="createItem">New Item</v-btn>
                </template>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <ValidationProvider name="Publisher" rules="integer">
                              <v-autocomplete
                                slot-scope="{errors,valid}"
                                :items="publishers"
                                item-text="name"
                                item-value="id"
                                v-model="editedItem.publisher_id"
                                :error-messages="errors"
                                :success="valid"
                                label="Publisher"
                                clearable
                              ></v-autocomplete>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <ValidationProvider name="Author" rules="integer">
                              <v-autocomplete
                                slot-scope="{errors,valid}"
                                :items="authors"
                                item-text="full_name"
                                item-value="id"
                                v-model="editedItem.author_id"
                                :error-messages="errors"
                                :success="valid"
                                label="Author"
                              ></v-autocomplete>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <ValidationProvider name="Name" rules="required|max:255">
                              <v-text-field
                                slot-scope="{errors,valid}"
                                :counter="255"
                                v-model="editedItem.name"
                                :error-messages="errors"
                                :success="valid"
                                label="Name"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <ValidationProvider name="Price" :rules="{required: true, regex: /^\d*\.?\d*$/}">
                              <v-text-field
                                slot-scope="{errors,valid}"
                                v-model="editedItem.price"
                                :error-messages="errors"
                                :success="valid"
                                label="Price"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <ValidationProvider name="ISBN">
                              <v-text-field
                                slot-scope="{errors,valid}"
                                v-model="editedItem.isbn"
                                :error-messages="errors"
                                :success="valid"
                                label="ISBN"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <ValidationProvider name="Format" rules="required">
                              <v-select
                                slot-scope="{errors,valid}"
                                :items="formats"
                                v-model="editedItem.format"
                                :error-messages="errors"
                                :success="valid"
                                label="Format"
                              ></v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <ValidationProvider name="Language" rules="required">
                              <v-select
                                slot-scope="{errors,valid}"
                                :items="languages"
                                v-model="editedItem.language"
                                :error-messages="errors"
                                :success="valid"
                                label="Language"
                              ></v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <ValidationProvider name="Tags">
                              <v-autocomplete
                                slot-scope="{errors,valid}"
                                :items="tags"
                                v-model="editedItem.tags"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                :success="valid"
                                label="Tags"
                                multiple
                              ></v-autocomplete>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-img :src="editedItem.image_url" v-if="editedItem.image_url"/>
                            <ValidationProvider name="Cover image" :rules="{required: editedIndex === -1}">
                              <v-file-input
                                slot-scope="{errors,valid}"
                                v-model="editedItem.image"
                                :error-messages="errors"
                                :success="valid"
                                label="Cover image"
                              ></v-file-input>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <ValidationProvider name="Book" :rules="{required: editedIndex === -1}">
                              <v-file-input
                                slot-scope="{errors,valid}"
                                v-model="editedItem.book"
                                :error-messages="errors"
                                :success="valid"
                                label="Book"
                              ></v-file-input>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12">
                            <ValidationProvider name="Description" rules="required">
                              <v-textarea
                                slot-scope="{errors,valid}"
                                v-model="editedItem.description"
                                :error-messages="errors"
                                :success="valid"
                                label="Description"
                              ></v-textarea>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-switch
                              v-model="editedItem.is_bestseller" label="Bestseller"
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-switch
                              v-model="editedItem.status" label="Active"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close" :disabled="isLoading">
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save" :disabled="invalid" :loading="isLoading">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </ValidationObserver>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" :disabled="isLoading" text @click="closeDelete">Cancel</v-btn>

                    <v-btn color="blue darken-1" text :loading="isLoading" @click="deleteItemConfirm">OK</v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </state-indicate>
        </template>
        <template v-slot:item.status={item}>
          <v-chip color="success" small v-if="item.status === statuses.active">active</v-chip>
          <v-chip color="primary" small v-else-if="item.status === statuses.inactive">inactive</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
        <div class="d-flex">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </div>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary">Reset</v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="meta.last_page"
        ></v-pagination>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import Product from '@/models/Product';
import ProductsApi from '@/api/products';
import PublishersApi from '@/api/publishers';
import AuthorsApi from '@/api/authors';
import TagsApi from '@/api/tags';
import {ValidationProvider, ValidationObserver, withValidation} from 'vee-validate';
import StateIndicate from "../../../components/utils/StateIndicate";

const STATUS_ACTIVE = 1;
const STATUS_INACTIVE = 0;

export default {
  name: "Crud",
  components: {
    ValidationProvider, ValidationObserver, StateIndicate
  },
  data() {
    return {
      loading: false,
      tags: [],
      authors: [],
      products: [],
      publishers: [],
      formats: ['Ebook'],
      languages: ['English', 'Afrikaans'],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
        price: '',
        author_id: '',
        publisher_id: '',
        image: null,
        book: null,
        slug: '',
        description: '',
        quantity: 0,
        format: '',
        language: '',
        isbn: '',
        tags: [],
        status: STATUS_INACTIVE,
        is_bestseller: false
      },
      defaultItem: {},
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Author', value: 'author.full_name'},
        {text: 'Publisher', value: 'publisher.name'},
        {text: 'Price', value: 'price'},
        {text: 'Status', value: 'status'},
        {text: 'ISBN', value: 'isbn'},
        {text: 'Tags', value: 'raw_tags'},
        {text: 'Bestseller', value: 'is_bestseller_formatted'},
        {text: 'Created at', value: 'created_at'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      meta: {},
      page: 1,
    }
  },
  watch: {
    async page() {
      this.fetchProducts()
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    statuses() {
      return {
        active: STATUS_ACTIVE,
        inactive: STATUS_INACTIVE,
      }
    },
  },
  created() {
    this.defaultItem = {...this.editedItem}
    this.fetchProducts()
    this.fetchAuthors()
    this.fetchPublishers()
    this.fetchTags()
  },
  methods: {
    fetchTags() {
      this.loading = true
      return TagsApi.list().then(ApiResponse => {
        this.tags = ApiResponse.getData();
        this.loading = false
      });
    },
    fetchAuthors(name) {
      this.loading = true
      return AuthorsApi.list(name).then(ApiResponse => {
        this.authors = ApiResponse.getData();
        this.loading = false
      });
    },
    fetchPublishers() {
      this.loading = true
      return PublishersApi.list().then(ApiResponse => {
        this.publishers = ApiResponse.getData();
        this.loading = false
      });
    },
    fetchProducts() {
      this.loading = true
      return Product.custom('admin/products').page(this.page).get().then(ApiResponse => {
        this.products = ApiResponse.getData();
        this.meta = ApiResponse.getMeta();
        this.loading = false
      });
    },
    createItem() {
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
    },
    editItem(item) {
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      this.$refs.indicate.isLoading = true
      try {
        await ProductsApi.delete(this.editedItem.slug)
        this.closeDelete()
        this.fetchProducts()
      } catch (e) {
      }
      this.$refs.indicate.isLoading = false
    },
    close() {
      this.$refs.observer.reset()
      this.dialog = false
      this.$nextTick(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async save() {
      if (await this.$refs.observer.validate()) {
        let formData = await this.getFormData()
        this.$refs.indicate.isLoading = true;
        if (this.editedIndex > -1) {
          try {
            await ProductsApi.update(this.editedItem.slug, formData);
            Object.assign(this.products[this.editedIndex], this.editedItem);
            this.close();
            this.fetchProducts()
          } catch (e) {
            alert('Error while for updating book')
          }
        } else {
          try {
            await ProductsApi.store(formData);
            this.close()
            this.fetchProducts()
          } catch (e) {
            alert('Error while for creating book')
          }
        }
        this.$refs.indicate.isLoading = false;
      }
    },
    async getFormData() {
      let formData = new FormData();
      if (!this.editedItem.author_id) {
        delete this.editedItem.author_id
      }
      Object.keys(this.editedItem).forEach(key => {
        formData.append(key, this.editedItem[key])
      });

      if (this.editedIndex > -1) {
        formData.append('_method', 'PUT')
      }

      return formData;
    }
  },
}
</script>
