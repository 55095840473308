var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"page":_vm.page,"server-items-length":_vm.meta.total,"items-per-page":_vm.meta.per_page,"loading":_vm.loading,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('state-indicate',{ref:"indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Products List")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mb-2",attrs:{"dark":"","color":"primary"},on:{"click":_vm.createItem}},[_vm._v("New Item")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Publisher","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-autocomplete',{attrs:{"items":_vm.publishers,"item-text":"name","item-value":"id","error-messages":errors,"success":valid,"label":"Publisher","clearable":""},model:{value:(_vm.editedItem.publisher_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "publisher_id", $$v)},expression:"editedItem.publisher_id"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Author","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-autocomplete',{attrs:{"items":_vm.authors,"item-text":"full_name","item-value":"id","error-messages":errors,"success":valid,"label":"Author"},model:{value:(_vm.editedItem.author_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "author_id", $$v)},expression:"editedItem.author_id"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"success":valid,"label":"Name"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Price","rules":{required: true, regex: /^\d*\.?\d*$/}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Price"},model:{value:(_vm.editedItem.price),callback:function ($$v) {_vm.$set(_vm.editedItem, "price", $$v)},expression:"editedItem.price"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"ISBN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"ISBN"},model:{value:(_vm.editedItem.isbn),callback:function ($$v) {_vm.$set(_vm.editedItem, "isbn", $$v)},expression:"editedItem.isbn"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Format","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-select',{attrs:{"items":_vm.formats,"error-messages":errors,"success":valid,"label":"Format"},model:{value:(_vm.editedItem.format),callback:function ($$v) {_vm.$set(_vm.editedItem, "format", $$v)},expression:"editedItem.format"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-select',{attrs:{"items":_vm.languages,"error-messages":errors,"success":valid,"label":"Language"},model:{value:(_vm.editedItem.language),callback:function ($$v) {_vm.$set(_vm.editedItem, "language", $$v)},expression:"editedItem.language"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-autocomplete',{attrs:{"items":_vm.tags,"item-text":"name","item-value":"id","error-messages":errors,"success":valid,"label":"Tags","multiple":""},model:{value:(_vm.editedItem.tags),callback:function ($$v) {_vm.$set(_vm.editedItem, "tags", $$v)},expression:"editedItem.tags"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.editedItem.image_url)?_c('v-img',{attrs:{"src":_vm.editedItem.image_url}}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Cover image","rules":{required: _vm.editedIndex === -1}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-file-input',{attrs:{"error-messages":errors,"success":valid,"label":"Cover image"},model:{value:(_vm.editedItem.image),callback:function ($$v) {_vm.$set(_vm.editedItem, "image", $$v)},expression:"editedItem.image"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Book","rules":{required: _vm.editedIndex === -1}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-file-input',{attrs:{"error-messages":errors,"success":valid,"label":"Book"},model:{value:(_vm.editedItem.book),callback:function ($$v) {_vm.$set(_vm.editedItem, "book", $$v)},expression:"editedItem.book"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-textarea',{attrs:{"error-messages":errors,"success":valid,"label":"Description"},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":"Bestseller"},model:{value:(_vm.editedItem.is_bestseller),callback:function ($$v) {_vm.$set(_vm.editedItem, "is_bestseller", $$v)},expression:"editedItem.is_bestseller"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":"Active"},model:{value:(_vm.editedItem.status),callback:function ($$v) {_vm.$set(_vm.editedItem, "status", $$v)},expression:"editedItem.status"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":isLoading},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid,"loading":isLoading},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":isLoading,"text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":isLoading},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]}}])})]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.statuses.active)?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v("active")]):(item.status === _vm.statuses.inactive)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_vm._v("inactive")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("Reset")])]},proxy:true}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.meta.last_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }