import axios from "axios";

export default {
    update(id, data) {
        return axios.put(`/api/authors/${id}`, data)
    },
    store(data) {
        return axios.post(`/api/authors`, data)
    },
    delete(id) {
        return axios.delete(`/api/authors/${id}`)
    },
    list(name) {
      return axios.get('/api/authors/list', {
        params: {
          search: name
        }
      })
    }
}
